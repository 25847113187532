<template>
  <div class="declare-container">
    <div v-if="isValidateImg" class="declare" ref="imageWrapper">
      <div class="declareTable" ref="declareTable">
        <div class="declareTitle">经营所得个人所得税扣缴明细单</div>
        <div class="declareLine">
          <div class="rightBorder" style="max-width: 16vw;">姓名</div>
          <div class="rightBorder" style="max-width: 40vw;">{{ argsData.name }}</div>
          <div class="rightBorder" style="max-width: 26vw;">证件类别</div>
          <div class="rightBorder" style="max-width: 26vw;">{{ argsData.idCardType }}</div>
          <div class="rightBorder" style="max-width: 26vw;">证件号码</div>
          <div class="rightBorder" style="max-width: 42vw;">{{ argsData.idCard }}</div>
        </div>
        <div class="declareLine">
          <div class="rightBorder" style="max-width: 16vw;">序号</div>
          <div class="rightBorder" style="max-width: 20vw;">所属月份</div>
          <div class="rightBorder" style="max-width: 20vw;">所得项目</div>
          <div class="rightBorder" style="max-width: 26vw;">应纳税所得额</div>
          <div class="rightBorder" style="max-width: 26vw;">应纳税额</div>
          <div class="rightBorder" style="max-width: 26vw;">扣缴税额</div>
          <div class="rightBorder" style="max-width: 42vw;">备注</div>
        </div>
        <div class="declareLine">
          <div class="rightBorder" style="max-width: 16vw;">1</div>
          <div class="rightBorder" style="max-width: 20vw;">{{ argsData.monthDay }}</div>
          <div class="rightBorder" style="max-width: 20vw;">经营所得</div>
          <div class="rightBorder" style="max-width: 26vw;">{{ argsData.taxBasis }}</div>
          <div class="rightBorder" style="max-width: 26vw;">{{ argsData.taxPayAmt }}</div>
          <div class="rightBorder" style="max-width: 26vw;">{{ argsData.taxPayAmt }}</div>
          <div class="rightBorder" style="max-width: 42vw;">{{ argsData.remark }}</div>
        </div>
        <div class="declareLine">
          <div class="declareInfo">
            <div>扣缴义务人名称：{{ argsData.levyName }}</div>
            <div>扣缴义务人纳税人识别号：{{ argsData.taxNum }}</div>
            <div>管辖税务机关：{{ argsData.taxAuthorities }}</div>
          </div>
        </div>
        <div class="declareLine declareTips">
          <div style="text-align: left;">
            声明:本扣缴明细单是根据国家《税收征管法》、《个人所得税法》、《委托代征管理办法》及自由职业者与薪航向平台签署的《自由职业者协议》相关规定填报的，是真实的、可靠的、完整的；如有疑问请拨打电话<a style="color: inherit;" href="tel:4008543600">4008543600</a>。本明细单仅限本人查询使用，如因使用不当，后果自负。
          </div>
        </div>
      </div>
      <div class="declareTipsOneGray" :style="{top: declareTipsOneGrayTop}">
        <div>如需获取纸质完税证明，请联系客服：<a style="color: #2d8cf0" href="tel:400-854-3600">400-854-3600</a></div>
      </div>

      <div class="declareProve" :style="{top: declareProveTop}">
        <img v-if="img" class="img" :src="img" />
      </div>
    </div>
    <van-empty v-else description="申报中，请耐心等待" />
  </div>
</template>
<script>

import { checkImgExists } from '@/libs/utils'
export default {
  name: 'declareDetail',
  components: {},
  data () {
    return {
      levyId: 0,
      url: '',
      isIosFlag: false,
      argsData: {},
      img: '',
      dataURL: '',
      isValidateImg: false,
      declareTipsOneGrayTop: 'auto',
      declareProveTop: 'auto',
      isLoaded: false
    }
  },
  created () {

  },
  methods: {
    isIos () {
      this.$toast.loading({
        message: '加载中...',
        duration: 0,
        forbidClick: true
      })

      const browser = {
        versions: (function () {
          const u = navigator.userAgent
          return {
            trident: u.indexOf('Trident') > -1, // IE内核
            presto: u.indexOf('Presto') > -1, // opera内核
            webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, // 火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), // 是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或者uc浏览器
            iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, // 是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, // 是否iPad
            webApp: u.indexOf('Safari') == -1 // 是否web应该程序，没有头部与底部
          }
        }()),
        language: (navigator.browserLanguage || navigator.language).toLowerCase()
      }

      if (browser.versions.ios || browser.versions.iPhone || browser.versions.iPad) {
        this.isIosFlag = true
      }

      // 申报回执单
      const args = JSON.parse(this.$route.query.args)

      const dateName = args.monthDay.replace(/-/ig, '')
      this.levyId = args.levyId
      this.img = `https://miniasst.xiaozhucc.com/images/${dateName}${args.levyId}.png`
      // this.img = 'https://miniasst.xiaozhucc.com/images/2021128.png' // 测试数据
      checkImgExists(this.img).then(res => {
        this.isValidateImg = true
        setTimeout(() => {
          console.log(this.$refs.declareTable.getBoundingClientRect())
          this.declareTipsOneGrayTop = this.$refs.declareTable.getBoundingClientRect().bottom + 6 + 'px'
          this.declareProveTop = this.$refs.declareTable.getBoundingClientRect().bottom + 40 + 'px'
        }, 0)
      }).catch(() => {
        this.isValidateImg = false
      })

      this.isLoaded = true
      setTimeout(() => {
        this.$toast.clear(true)
        this.argsData = args
      }, 100)
    }
  },
  mounted () {
    this.isIos()
  }
}
</script>
<style scoped lang="less">
.declare-container{
  .declare {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: relative;
    .declareTable {
      position: absolute;
      z-index: 100;
      transform: scale(0.54);
      transform-origin: 4vw 0;
      box-sizing: border-box;
      min-width: 178vw;
      border-collapse: collapse;
      font-size: 3.47vw;
      line-height: 4.8vw;
      background-color: #fff;
      border: 1px solid #999;
      margin-top: 4vw;
      .declareTitle {
        box-sizing: border-box;
        width: 100%;
        height: 80px;
        line-height: 80px;
        font-size: 30px;
        font-weight: bold;
        border-bottom: 1px solid #999;
      }
      .declareLine {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        border-bottom: 1px solid #999;
        &:last-child {
          border: 0;
        }
        .rightBorder {
          padding: 16px 0;
          width: 100%;
          text-align: center;
          border-right: 1px solid #999;
          box-sizing: border-box;
          &:last-child {
            border: 0;
          }
        }
      }
      .declareInfo {
        text-align: left;
        line-height: 50px;
        padding: 16px 10px;
      }
      .declareTips {
        box-sizing: border-box;
        line-height: 50px;
        padding: 16px 10px;
        .iosTips {
          font-size: 8px;
          line-height: 10px;
        }
      }
    }
    .declareTipsOneGray {
      position: absolute;
      width: 100%;
      font-size: 24px;
      color: #999;
      z-index: 100;
    }
    .declareProve {
      position: absolute;
      width: 100%;
      left: -2vw;
      font-size: 24px;
      color: #999;
      overflow: hidden;
      .img {
        width: 100%;
        max-width: 100vw;
        height: 141.6vw;
        transform: scale(1.32);
        transform-origin: top;
      }
    }
  }
}
</style>
